import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../styles';
import News from '../components/News'
import { graphql } from "gatsby"
import GenericBanner from '../components/GenericBanner'
import Footer from '../components/Footer'

const useStyles = makeStyles(theme => { return {
  ...styles(theme),
}})

const NewsPage = ({
      data: {
        allMarkdownRemark: { edges },
      },
    }) => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div className={classes.root}>
        <GenericBanner label="NEWS" />
        <News posts={edges} />
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default NewsPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          html
          fields { slug }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            image
          }
        }
      }
    }
  }
`